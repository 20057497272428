
import { defineComponent, PropType } from 'vue'
import { Literal } from 'rdf-js'
import { MultiPointer } from 'clownface'
import { taggedLiteral } from 'clownface/filter'
import { displayLanguage } from '@/store/serializers'

export default defineComponent({
  name: 'TermWithLanguage',
  props: {
    values: {
      type: Object as PropType<MultiPointer<Literal> | Literal[]>,
      default: undefined,
    },
    selectedLanguage: {
      type: String,
      required: true,
    },
  },

  computed: {
    value (): string | undefined {
      if (Array.isArray(this.values)) {
        const term =
          this.values?.find(({ language }) => language === this.selectedLanguage) ||
          this.values?.find(({ language }) => !language)

        return term?.value
      }

      const term = this.values?.filter(taggedLiteral([
        this.selectedLanguage,
        ...displayLanguage,
        '*'
      ]))

      return term?.value
    },
  },
})
